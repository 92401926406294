import React from 'react'
import PropTypes from 'prop-types'

import ButtonStyled from './styled'

class Button extends React.PureComponent {
  handleClicked = () => {
    this.props.onClick(this.props.data)
  }

  render() {
    let className = this.props.className ? `${this.props.className}` : ''
    className += this.props.invert ? ' invert' : ''
    return (
      <ButtonStyled id={this.props.id} data-test={this.props.id} className={className} onClick={this.handleClicked} disabled={this.props.disabled}>
        {this.props.text}
      </ButtonStyled>
    )
  }
}

Button.defaultProps = {
  id: 'button',
  text: '',
  invert: false,
  disabled: false,
}

Button.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  invert: PropTypes.bool,
}

export default Button
