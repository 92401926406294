import styled from 'styled-components'

export default styled.button`
  width: 152px;
  height: 40px;
  background: #981214;
  border: none;
  color: white;
  text-decoration: none;
  outline: none;
  font-family: 'Prompt', sans-serif;
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.2s;
  &.invert {
    background: #232529;
    border: 1px solid #981214;
    &:hover {
      background: rgba(180, 35, 37, 0.1);
    }
  }
  &:hover {
    background: #b42325;
  }
  &:disabled {
    background: #323232;
    pointer-events: none;
    color: #4a4a4a;
  }
`
